<template>
  <el-dialog class="smoke" :visible="visible" :showClose="false" :modal="false" lock-scroll style="position: absolute">
<!--  排烟口-->
    <div class="outlet" v-if="parameter.outlet">
      <img :src="zoomOutImg" class="zoomOut" @click="onClose"/>
      <img :src="outletImg" class="outletImg" style="width: 100%">
      <img  :src="require('@/assets/img/5/5-5-3.png')" class="openImg" :class="parameter.class&&parameter.class.openImg">
<!--          火警-拨-->
      <div  ref="huojingyuan" class="huojingyuan"  @click="buttonSelect('yuan',$event)" ></div>
      <div class="reset-rotation">
        <!--        复位1-->
        <div class="resetquan1 cursorhover" :class="parameter.class&&parameter.class.resetquan1" @click="buttonSelect('quan1')"></div>
        <div class="resetgun1 rotation1" :class="parameter.class&&parameter.class.resetgun1"><img :src="gunImg"></div>
        <div class="resetfangxiang1 rotation1 cursorhover" :class="parameter.class&&parameter.class.resetfangxiang1" @click="buttonSelect('xiang1',$event)"><img :src="directionImg"></div>

        <!--        复位2-->
        <div class="resetquan2 cursorhover" :class="parameter.class&&parameter.class.resetquan2" @click="buttonSelect('quan2')"></div>
        <div class="resetgun2 rotation2" :class="parameter.class&&parameter.class.resetgun2"><img :src="gunImg"></div>
        <div class="resetfangxiang2 rotation2 cursorhover" :class="parameter.class&&parameter.class.resetfangxiang2" @click="buttonSelect('xiang2',$event)"><img :src="directionImg"></div>
      </div>
    </div>
  </el-dialog>
</template>

<script>
export default {
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    parameter: {},
    close: {
      type: Function,
    },
  },
  data() {
    return {
      zoomOutImg:require('@/assets/img/zoomOut.png'),
      outletImg:require('@/assets/img/5/5-5-2.png'),
      gunImg:require('@/assets/img/5/gun.png'),
      directionImg:require('@/assets/img/5/direction.png'),
      isShow: false,
      closeAudio:'magnifying-xiao.mp3',
      keyboardAudio:'keyboard.mp3',
    };
  },
  mounted() {
    // console.log(this.parameter)
  },
  created() {
    // console.log(this.parameter)
  },
  methods: {
    show() {
      this.isShow = true;
    },
    onClose() {
      this.close && this.close();
      this.$emit('customPlay2', this.closeAudio);
    },
    buttonSelect(value,e) {
      if(value==='yuan'){
        const this_ = e.target;
        console.log('yuan1',this_.classList['value'])
        if(this_.classList.contains('huojingyuanhua1')){
          this_.classList.remove('huojingyuanhua1');
          this.parameter['huojingyuan']['class_']=this_.classList['value'];
         console.log('yuan',this_.classList[0],this.parameter[value])


        }
        else{
          this_.classList.add('huojingyuanhua1');
          setTimeout(()=>{
            this_.classList.remove('huojingyuanhua1');
          },1500)
        }
      }
      console.log('buttonSelect',value,this.parameter[value])
      if(this.parameter[value]){
        if(this.parameter[value]['up']&&(!this.parameter[value]['submit']||(this.parameter[value]['submit']&&(this.parameter[value]['submit']['equal']===1&&this.parameter.branchKey===this.parameter[value]['submit']['branch']||this.parameter[value]['submit']['equal']===3)))){
          let arr=this.parameter[value]['up'];
          let new_=this.arrcover(arr,this.parameter)
          this.$set(this.parameter,new_)
          console.log('buttonSelect-up',arr)
        }
      }
      let submit=[];let update_=[];
      if(this.parameter[value]&&this.parameter[value]['submit']){
        submit=this.parameter[value]['submit'];
      }
      if(this.parameter[value]&&this.parameter[value]['update']){
        update_= this.parameter[value]['update'];
      }

      this.$emit('customOther', value,submit,update_);
      this.$emit('customPlay2', this.keyboardAudio);
    },
    arrcover(arr,new_){
      Object.keys(arr).forEach((k)=>{
        if(typeof arr[k] === 'object'){
          if(!new_[k]){
            new_[k]={};
          }
          this.arrcover(arr[k],new_[k])
        }
        else{
          new_[k]=arr[k];
        }
      })
      return new_;
    },
  },
};
</script>

<style lang="less" scoped>
.el-dialog__wrapper {
  position: absolute;
}
.hiden {
  display: none !important;
}
.show {
  display: block !important;
}
/deep/.el-dialog {
  display: flex;
  flex-direction: column;
  margin: 0 !important;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-height: calc(100% - 1.875rem);
  max-width: calc(100% - 1.875rem);
  background: transparent;
  box-shadow: none;
  width: auto;
}

/deep/.el-dialog .el-dialog__body {
  max-height: 100%;
  flex: 1;
  -ms-flex: 1 1 auto;
  overflow-y: hidden;
  overflow-x: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}
.zoomOut{
  position: absolute;
  top: 1rem;
  right: 1rem;
}
.outlet{
  position: relative;

  img:not(.zoomOut){
    width: 100%;
  }
  .resetquan1.bling,.resetquan2.bling{
    background: orange;
  }
  .openImg{
    position: absolute;
    width: 27.7% !important;
    top: 26.5%;
    left: 61.827%;
  }
  .huojingyuan {
    //height: 4%;
    //width: 3%;
    height: 4%;
    width: 2.7%;
    border-radius: 2.7rem;
    background: orange;
    position: absolute;
    top: 69.2%;
    left: 21%;
  }
  .huojingyuan:hover {
    cursor: pointer;
  }
  .huojingyuanhua1 {
    animation: moveRightLeft 1.5s infinite alternate;
    animation-iteration-count: 1;
  }
  .huojingyuanhua {
    transform: translateX(5.7rem);
  }
  @keyframes moveRightLeft {
    0% {
      transform: translateX(0);
    } /* 起始位置 */
    50% {
      //transform: translateX(5.7rem);
      transform: translateX(205%);
    } /* 向左移动 */
    100% {
      transform: translateX(0rem);
    }
    /* 返回原位置 */
  }
  .reset-rotation {
    color: #000;
    .resetquan1 {
      position: absolute;
      top: 53.2%;
      left: 23.1%;
      height: 5.5%;
      width: 4%;
      border-radius: 4.5rem;
    }
    resetquan1:hover{
      cursor: pointer;
    }
    .resetgun1 {
      position: absolute;
      top: 54.5%;
      left: 8.8%;
      width: 16.4%;
      //height: 7%;
      //transform: rotate(0.5turn);
      //transform-origin: 99% 55%;
      img{
        width: 100%;
      }

    }
    .resetgun1zhuan {
      animation: resetgun1zhuan 1.3s linear;
      animation-fill-mode: forwards;
      animation-iteration-count: 1;
    }
    .resetfangxiang1 {
      position: absolute;
      left: 10%;
      top: 44.5%;
      width: 2.3%;

      img{
        width: 100%;
      }
    }
    .resetquan2 {
      position: absolute;
      top: 21.8%;
      left: 23%;
      height: 5.5%;
      width: 4%;
      border-radius: 4.5rem;
    }
    .resetgun2 {
      position: absolute;
      top: 22.59%;
      left: 8.8%;
      width: 16.4%;
      img{
        width: 100%;
      }
    }
    .resetgun2zhuan {
      animation: resetgun2zhuan 1.3s linear;
      animation-fill-mode: forwards;
      animation-iteration-count: 1;
    }
    .resetfangxiang2 {
      position: absolute;
      top: 11.5%;
      left: 10%;
      width: 2.3%;
      img{
        width: 100%;
      }
    }
  }

  @keyframes resetgun1zhuan {
    0% {
      transform: rotate(0);
      transform-origin: 99% 55%;
      -webkit-transform: rotate(0);
      -webkit-transform-origin: 99% 55%;

    }

    95% {
      -webkit-transform: rotate(0.5turn);
      transform: rotate(0.5turn);
      -webkit-transform-origin: 99% 55%;
      //transform-origin: 95% 24%;
      transform-origin: 99% 55%;
      opacity: 1;
    }
    100% {
      -webkit-transform: rotate(0.5turn);
      transform: rotate(0.5turn);
      -webkit-transform-origin: 99% 55%;
      //transform-origin: 95% 24%;
      opacity: 0;
      transform-origin: 99% 55%;
    }
  }
  @keyframes resetgun2zhuan {
    0% {
      -webkit-transform: rotate(0);
      transform: rotate(0);
      -webkit-transform-origin: 99% 55%;
      transform-origin: 99% 55%;
    }

    95% {
      -webkit-transform: rotate(1turn);
      transform: rotate(1turn);
      -webkit-transform-origin: 99% 55%;
      transform-origin: 99% 55%;
      opacity: 1;
    }

    100% {
      -webkit-transform: rotate(1turn);
      transform: rotate(1turn);
      -webkit-transform-origin: 99% 55%;
      transform-origin: 99% 55%;
      opacity: 0;
    }
  }

  .fangxianghide {
    animation: xianghide 1.3s linear;
    animation-fill-mode: forwards;
    animation-iteration-count: 1;
  }
  @keyframes xianghide {
    0% {
    }

    95% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
  .cursorhover{
    cursor: pointer
  }
}

</style>
