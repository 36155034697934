<template>
  <div class="plugins">

    <div class="ws-windows" style="position: absolute;top: 37.802%;left: 19.4%;height: 25.37%;width: 16.3%;">
      <div class="windows-comp">
        <div class="glass-comp" :class="windowStatus">
          <div class="window-frame-comp">
            <div class="window-comp window-cover-comp">
              <div class="window-face-0-comp"></div>
              <div class="window-face-2-comp"></div>
              <div class="window-face-1-comp"></div>
              <div class="window-face-3-comp"></div>
              <div class="window-face-4-comp"></div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="ws-screen" style="position: absolute; top: 8.7%;left: 47.6%;height: 5.2%;width: 37.86%;">
      <div class="screen-comp" :class="screenStatus"></div>
    </div>

    <div class="ws-zoomImg" style="position: absolute;left: 50%;top: 50%" v-if="visible">
<!--      开窗按钮-->
      <div  class="window-inner" v-if="parameter.window">
        <img :src="require('@/assets/img/zoomOut.png')"  class="zoomOut" @click="onClose" />
        <img  :src="require('@/assets/img/12-6-1-1.png')" style="width: 30rem;">
        <div  class="button " @click="buttonSelect(parameter.switch)" :class="parameter.switch"></div>
        <div  class="light power"></div>
        <div  class="light" ></div>
      </div>
<!--      挡烟垂壁按钮-->
      <div  class="smokescreen-inner"  v-if="parameter.screen">
        <img :src="require('@/assets/img/zoomOut.png')" class="zoomOut" @click="onClose" />
        <!--      -->
        <img  :src="require('@/assets/img/wei16/16-4-2.png')" style="width: 30rem; border-radius: 8px;">
        <div  class="buttons">
          <div  class="up" @click="buttonSelect('upper')"></div>
          <div  class="down" @click="buttonSelect('down')"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {

    };
  },
  props: {
    // open:false,
    visible:false,
    windowStatus:false,
    screenStatus:false,
    parameter: {},
    close: {
      type: Function,
    },
  },

  created(){
    console.log('window-inner',this.parameter)
  },
  methods:{

    openClose(){
      console.log(123412)
      let arr = ['rotate(-45deg)','rotate(0deg)','rotate(45deg)']
      if(this.i==0){
        this.flag=true
      }
      if(this.i==2){
        this.flag=false
      }
      if(this.flag){
        this.i++
      }else {
        this.i--
      }
      if(this.i!=1){
        this.$parent.play2('12-5-4电动排烟窗开启.mp3')

      }
      this.aa = arr[this.i]
      this.$emit('open',this.i)
    },
    show() {
      this.isShow = true;
    },
    onSwitch(){
      this.trans = !this.trans;

    },

    onDown(parms) {
      if(!this.keywordOption[parms]) return
      this.keyCode = parms;
      this.title = this.keywordOption[parms]['title']
      this.$parent.play2('12-6-2挡烟垂壁上升声.mp3')
    },
    onClose() {
      this.close && this.close();
      // this.$parent.play2('zoomin.mp3')
    },

    buttonSelect(value,e) {

      console.log('buttonSelect',value,this.parameter[value])
      if(this.parameter[value]){
        if(this.parameter[value]['up']){
          let arr=this.parameter[value]['up'];
          let new_=this.arrcover(arr,this.parameter)
        }
        // if(this.parameter[value]['up']&&(!this.parameter[value]['submit']||(this.parameter[value]['submit']&&(this.parameter[value]['submit']['equal']===1&&this.parameter.branchKey===this.parameter[value]['submit']['branch']||this.parameter[value]['submit']['equal']===3)))){
        //   let arr=this.parameter[value]['up'];
        //   let new_=this.arrcover(arr,this.parameter)
        //   this.$set(this.parameter,new_)
        //   console.log('buttonSelect-up',arr)
        // }
      }
      let submit=[];let update_=[];
      if(this.parameter[value]&&this.parameter[value]['submit']){
        submit=this.parameter[value]['submit'];
      }
      if(this.parameter[value]&&this.parameter[value]['update']){
        update_= this.parameter[value]['update'];
      }

      this.$emit('customOther', value,submit,update_);
      this.$emit('customPlay2', this.keyboardAudio);
    },
    arrcover(arr,new_){
      Object.keys(arr).forEach((k)=>{
        if(typeof arr[k] === 'object'){
          if(!new_[k]){
            new_[k]={};
          }
          this.arrcover(arr[k],new_[k])
        }
        else{
          new_[k]=arr[k];
        }
      })
      return new_;
    },
  }
};

</script>

<style lang="less" scoped>
.ws-windows{
  .open .window-cover-comp {
    -webkit-transform: rotateX(-45deg);
    transform: rotateX(-45deg);
  }

  .windows-comp .glass-comp,.windows-comp {
    height: 100%;
    width: 100%;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }

  .windows-comp {
    position: absolute;
    top: 0;
    left: 0;
  }
  .windows-comp .glass-comp {
    position: relative;
  }
  .windows-comp .glass-comp div {
    position: absolute;
  }

  .window-frame-comp {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    -webkit-perspective: 37.5rem;
    perspective: 37.5rem;
  }
  .window-cover-comp {
    -webkit-transform-origin: bottom center;
    transform-origin: bottom center;
    bottom: 0;
  }
  .window-comp {
    width: 100%;
    top: 0;
    bottom: 0;
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d;
    -webkit-transform: rotateY(0deg);
    transform: rotateY(0deg);
    -webkit-transition: -webkit-transform 6s;
    transition: -webkit-transform 6s;
    transition: transform 6s;
    transition: transform 6s, -webkit-transform 6s;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    z-index: 9999;
    -webkit-perspective: 800px;
    perspective: 800px;
    -webkit-box-shadow: inset -5px -5px 5px #000;
    box-shadow: inset -5px -5px 5px #000;
  }
  .window-face-0-comp {
    background-color: rgba(0, 0, 0, 0.1);
    -webkit-transform: translateZ(-0.5rem);
    transform: translateZ(-0.5rem);
  }
  .window-face-1-comp {
    -webkit-transform: translateZ(0);
    transform: translateZ(-35);
  }
  .window-face-0-comp,.window-face-1-comp {
    left: -0.1875rem;
    top: -0.1875rem;
    right: -0.1875rem;
    bottom: -0.1875rem;
    border: 0.6rem solid #636e77;
    background-color: rgba(149, 227, 187, 0.1);
    -webkit-backdrop-filter: blur(1px);
    backdrop-filter: blur(1px);
    border-radius: 0;
  }
  .window-face-2-comp {
    height: 0.5rem;
    left: -0.1875rem;
    right: -0.1875rem;
    top: 0;
    border: 0.1875rem solid #636e77;
    border-radius: 0;
    background-color: #636e77;
    -webkit-transform: translate3D(0, -0.4688rem, -0.1875rem) rotateX(90deg);
    transform: translate3D(0, -0.4688rem, -0.1875rem) rotateX(90deg);
  }
  .window-face-3-comp {
    width: 0.375rem;
    top: -0.1875rem;
    right: 0;
    bottom: -0.1875rem;
    border: 0.1875rem solid #636e77;
    border-radius: 0;
    background-color: #636e77;
    -webkit-transform: translate3D(0.4688rem, 0, -0.1875rem) rotateY(90deg);
    transform: translate3D(0.4688rem, 0, -0.1875rem) rotateY(90deg);
  }
  .window-face-4-comp {
    height: 0.375rem;
    left: -0.1875rem;
    right: -0.1875rem;
    bottom: 0;
    border: 0.1875rem solid #636e77;
    border-radius: 0;
    background-color: rgba(0, 0, 0, 0.1);
    -webkit-transform: translate3D(0, 0.4688rem, -0.1875rem) rotateX(90deg);
    transform: translate3D(0, 0.4688rem, -0.1875rem) rotateX(90deg);
  }
}
.ws-screen{
  .screen-comp {
    background: url("https://bankaoedu.com/files/xc/chuibi-2.png") no-repeat;
    background-size: 100% auto;
    background-position: bottom;
    height: 2rem;
  }
  .screen-comp.upper {
    height: 2rem;
    -webkit-transition: height 5s linear;
    transition: height 5s linear;
  }
  .screen-comp.down {
    height: 15.75rem;
    -webkit-transition: height 5s linear;
    transition: height 5s linear;
  }
  .screen-comp.down_ {
    height: 15.75rem;
  }
}
.ws-zoomImg{
  display: flex;
  flex-direction: column;
  margin: 0 !important;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-height: calc(100% - 1.875rem);
  max-width: calc(100% - 1.875rem);
  background: transparent;
  box-shadow: none;
  width: auto;
  .zoomOut{
    position: absolute;
    top: 1rem;
    right: 1rem;
  }
  .window-inner {
    position: relative;
    border: .6rem solid #fff;
    border-radius: 1.2rem;
    .light {
      position: absolute;
      top: 12.4%;
      height: 8rem;
      width: 8rem;
      left: 14.7%;
    }
    .light.power {
      background: url('https://bankaoedu.com/files/xc/red_open.png') no-repeat;
      background-size: 100% auto;
      opacity: .3;
    }
    .light.run {
      left: 56.3%;
      background: url('https://bankaoedu.com/files/xc/green_open.png') no-repeat;
      background-size: 100% auto;
    }
    .button {
      position: absolute;
      height: 3.75rem;
      width: 3.75rem;
      top: 52.120944%;
      left: 42.3%;
      border-radius: 3.75rem;
      background-color: #333;
      -webkit-filter: drop-shadow(.0625rem .0625rem .125rem #000);
      filter: drop-shadow(.0625rem .0625rem .125rem black);
      z-index: 1;
      -webkit-box-shadow: -0.1875rem -0.1875rem 0.375rem hsla(0,0%,100%,.5137254901960784), 0.1875rem 0.1875rem 0.3125rem rgba(94,104,121,.688), inset 0.0625rem 0.0625rem 0.125rem hsla(0,0%,100%,.8);
      box-shadow: -0.1875rem -0.1875rem 0.375rem hsla(0,0%,100%,.5137254901960784), 0.1875rem 0.1875rem 0.3125rem rgba(94,104,121,.688), inset 0.0625rem 0.0625rem 0.125rem hsla(0,0%,100%,.8);
    }
    .button:after {
      position: absolute;
      content: "";
      height: 2.5rem;
      width: .5rem;
      border-radius: .5rem;
      background-color: #fff;
      bottom: 1.8rem;
      left: 50%;
      margin-left: 0;
      -webkit-transform-origin: bottom center;
      transform-origin: bottom center;
      -webkit-transition: -webkit-transform .5s linear;
      transition: -webkit-transform .5s linear;
      transition: transform .5s linear;
      transition: transform .5s linear, -webkit-transform .5s linear;
      transform: var(--aa);

    }

    .button.open{
      -webkit-transition: -webkit-transform .5s linear;
      transition: -webkit-transform .5s linear;
      transition: transform .5s linear;
      transition: transform .5s linear, -webkit-transform .5s linear;
      transform: rotate(-45deg)
    }
    .button.close{
      -webkit-transition: -webkit-transform .5s linear;
      transition: -webkit-transform .5s linear;
      transition: transform .5s linear;
      transition: transform .5s linear, -webkit-transform .5s linear;
      transform: rotate(45deg)
    }
    .button.stop{
      -webkit-transition: -webkit-transform .5s linear;
      transition: -webkit-transform .5s linear;
      transition: transform .5s linear;
      transition: transform .5s linear, -webkit-transform .5s linear;
      transform: rotate(0deg)
    }
  }
  .smokescreen-inner {
    scale: .8;
    position: relative;
    border: 8px solid #fff;
    border-radius: 16px;
    .buttons {
      position: absolute;
      width: 2.5rem;
      height: 21.375rem;
      left: 7.325rem;
      top: 13.75rem;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column;
      -webkit-box-pack: justify;
      -ms-flex-pack: justify;
      justify-content: space-between;
      div {
        width: 5.8rem;
        height: 5.8rem;
        border-radius: 6.5rem;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        cursor: pointer;
      }
    }
    .buttons .down, .buttons .up {
      position: relative;
    }
  }
}
</style>