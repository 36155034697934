<template>
  <div class="question">
    <div class="main-container">
      <section class="app-main">
        <div class="container" :class="{ container_full:isFullscreen}" ref="container">
          <div class="main-background">
            <img ref="mainImg" style="width: 100%; height: 100%; object-fit: cover;" :src="branch['bg_img']"/>
          </div>
          <div class="title">
            <span style="font-size: 1.8rem">{{ question["name"] || "-" }}</span>
            <span class="ct">答题倒计时：</span>
            <span>{{ timeLable }}</span>
          </div>

          <!--  -->
          <div class="main">
            <div class="sub-title">
              <span style="font-size: 1.5rem">{{topic[activeKey]["name"]}}</span>
              <div class="progress">
                <i class="el-icon-time"></i>
                <span>当前进度:</span>
                <el-progress :percentage="percentage" :color="customColor" style="width: 10rem"></el-progress>
              </div>
            </div>
            <!--           选择题-->
            <div class="choose" v-if="branch['choose'] && branch['choose']['title']">
              <div class="choose-title"> {{ branch["choose"]["title"] }} </div>
              <!-- 多选 -->
              <div class="choose-option" v-if="branch['choose']['type'] == 'check'" >
                <div class="option" v-for="(item, index) in branch['choose']['chooseOption']" :key="index" >
                  <el-checkbox-group v-model="ckeckList" @change="submitAnswer(ckeckList)">
                      <el-checkbox :label="item.value">{{ item.name }}</el-checkbox>
                  </el-checkbox-group>
                </div>
              </div>

              <!-- 单选 -->
              <div class="choose-option" v-if="branch['choose']['type'] == 'radio'" >
                <div class="option" v-for="(item, index) in branch['choose']['chooseOption']" :key="index">
                  <el-radio-group v-model="radio" @change="submitAnswer(radio)">
                      <el-radio :label="item.value">{{ item.name }}</el-radio>
                  </el-radio-group>
                </div>
              </div>
            </div>
            <!--操作题的题目-->
            <div class="operate" v-if="branch['type'] == 'operate'&&branch['ques_tips']">
              <div class="operate-title">
                {{ branch["ques_tips"] }}
              </div>
            </div>
          </div>

          <!-- 按钮栏 -->
          <div class="toolbar">
            <div class="toolbar-content">
              <div class="btns">
                <el-button type="primary tips" @click="showTips">提示</el-button>

                <div class="right-btn">

                  <el-button v-if="nextShow" type="primary" :disabled="buttondisable" ref="next" @click="next">下一步</el-button>
                  <el-button type="primary" @click="onSubmit"  :disabled = "subDisabled" >提交</el-button>
                  <el-button type="primary" @click="reset(1)">重做</el-button>
                  <el-button type="primary" @click="goBack">返回</el-button>
                </div>
              </div>
            </div>
          </div>
          <!-- 场景切换 -->
          <div class="navs" v-show="branch['sceneChangeShow']">
            <div class="navs-header">场景导航</div>
            <div class="navs-list">
              <div v-for="(item, index) in topic[activeKey]['scene_change']" v-show="(!item.branch&&item.branch!==0)||item.branch===branchKey" :key="index"  :class="item.class" :data-aa="item.class" @click="sceneChange(index)" >
                <img :src="item.img" />
                <span >{{item.name}}</span>
              </div>
            </div>
          </div>
          <!-- 放大镜 -->
          <div class="zoom" v-for="(item, index) in topic[activeKey]['zoomIn']"
               v-show="imgKey===item.scene"
               :style="{ left: item['left'], top: item['top'], }" >
            <img :src="zoomInImg" @click="showZoomImg(item.zoom,item.zoom.modalType)" />
          </div>

          <!--消防室-->
          <fireController
              v-if="modalType.fireControllerShow"
              :visible="modalType.fireControllerShow"
              :parameter="modalType.modalData"
              :close="() => (modalType.fireControllerShow = false)"
              @customPlay="play"
              @customPlay2="play2"
              @customPlay3="play3"
              @customOther="customOther"
              ref="fireController"
              class="fireControllerShow"
          ></fireController>
          <!--          水泵房一-->
          <waterPump
              v-show="modalType.waterPumpShow"
              :visible="modalType.waterPumpShow"
              :parameter="modalType.modalData"
              :close="() => (modalType.waterPumpShow = false)"
              @customPlay="play"
              @customPlay2="play2"
              @customPlay3="play3"
              @customOther="customOther"
              class="waterPump"
          ></waterPump>
          <!--          工具箱-->
          <tools v-show="modalType.toolboxShow"
                 :visible="modalType.toolboxShow"
                 :branchKey="branchKey"
                 @customPlay="play"
                 @customPlay2="play2"
                 @customPlay3="play3"
                 @subToolAnswer="subToolAnswer"
                 @customOther="customOther"
                 ref="tools"
          ></tools>
          <!--          排烟口-->
          <smoke  v-show="modalType.smokeShow"
                  :visible="modalType.smokeShow"
                  :parameter="modalType.modalData"
                  :close="() => (modalType.smokeShow = false)"
                  @customPlay="play"
                  @customPlay2="play2"
                  @customPlay3="play3"
                  @subToolAnswer="subToolAnswer"
                  @customOther="customOther"
          ></smoke>
          <div class="tool-other">
            <div v-for="(item) in branch.toolOther">
              <img :class="item.toolClass" :src="item['toolImg']" :style="item.toolStyle" :ref="item.ref"/>
              <div v-if="item['toolHtml']" v-html="item['toolHtml']"></div>
            </div>
          </div>
          <div class="fengkous" v-if="branch.fengkou">
            <div class="fengkou" :class="'fengkou'+item" v-for="(item, index) in 3">
              <div class="kou " :data-index="'test'+(3*index+item2)" v-for="(item2, index2) in 3" @click="operateAnswer((3*index+item2), $event)"></div>
            </div>
          </div>

          <div>
            <div class="shutter-box" v-show="branch.shutterscene===imgKey" style="top: 30.738%; left: 32.36%; height: 45.9334%; width: 41.18%; position: absolute; z-index: 2;">
              <div ref="shutter" class="shutter" >
                <div class="side"></div>
              </div>
            </div>
            <div class="shutter-box" v-if="branch.shutterscene1===imgKey" v-show="branch.shutterscene1===imgKey" style="top: 30.738%; left: 32.36%; height: 45.9334%; width: 41.18%; position: absolute; z-index: 2;">
              <div ref="shutter1" class="shutter shutter1" >
                <div class="side"></div>
              </div>
            </div>
          </div>
          <!--          图片放大镜-->
          <zoomImg
              v-if="modalType.zoomImgShow"
              :parameter="modalType.modalData"
              :close="() => (modalType.zoomImgShow = false)"
              @customPlay="play"
              @customPlay2="play2"
              @customPlay3="play3"
              @customOther="customOther"
              ref="ZoomImg"
          ></zoomImg>
          <terminalElectric
              v-if="modalType.terminalElectricShow"
              :visible="modalType.terminalElectricShow"
              :parameter="modalType.modalData"
              :close="() => (modalType.terminalElectricShow = false)"
              @customPlay="play"
              @customPlay2="play2"
              @customPlay3="play3"
              @customOther="customOther"
              class="terminalElectric"
          >
          </terminalElectric>
          <antechamber
              v-if="modalType.antechamberShow"
              :visible="modalType.antechamberShow"
              :parameter="modalType.modalData"
              :close="() => (modalType.antechamberShow = false)"
              @customPlay="play"
              @customPlay2="play2"
              @customPlay3="play3"
              @customOther="customOther"
              class="antechamber"
          >
          </antechamber>

          <windowsScreen
              v-if="branch.windowsScreen"
              :parameter="modalType.modalData"
              :windowStatus="branch.windowStatus"
              :screenStatus="branch.screenStatus"
              :visible="modalType.windowsScreenShow"
              :close="() => (modalType.windowsScreenShow = false)"
              @customPlay="play"
              @customPlay2="play2"
              @customPlay3="play3"
              @customOther="customOther"
              class="windowsScreen"
          ></windowsScreen>
          <!--          提示显示-->
          <div v-if="tipsShow&&branch['tips']" style="width: 100%;height: 100%;position: absolute;left: 0;top: 0;z-index: 2009">

            <div class="tips_show">
              <div>
                <div>{{branch['tips']['correctAnswer']}}</div>
                <div>正确操作</div>
                <div v-for="(item, index) in branch['tips']['correctOperation']" :style="item.style">{{item.step}}</div>
              </div>
            </div>
          </div>

          <div class="fullscreen" v-if="!isFullscreen" @click="fullscreenClick">进入全屏</div>
        </div>
      </section>
    </div>
    <!--音频1 题目 -->
    <audio v-show="false" ref="audio" controls src="" preload="metadata" @play="handleMusicPlay" @ended="handleMusicEnded">
      <source src="" />
    </audio>
    <!--    音频2 按钮、放大、放小-->
    <audio v-show="false" ref="audio2" controls src="" preload="metadata">
      <source src="" />
    </audio>
    <!--    音频3 需要一直循环的声音-->
    <audio v-show="false" ref="audio3" controls src="" preload="metadata">
      <source src="" />
    </audio>
  </div>
</template>
<script>
import zoomImg from "@/views/weibao/components/zoomImg.vue";//只显示图片
import fireController from "@/views/weibao/components/fireController.vue";//消控室,疏散走道,消防泵控制柜,末端装置试水阀
import tools from "@/views/weibao/components/tools.vue";//工具箱
import waterPump from "@/views/weibao/components/waterPump.vue";//水泵房一
import terminalElectric from "@/views/weibao/components/terminalElectric.vue";//末端配电装置
import antechamber from "@/views/weibao/components/antechamber.vue";//风机房-前室
import {arraysAreEqual, fomatTime} from "@/util/arr.js";
import screenfull from "screenfull";
import smoke from "@/views/weibao/components/roomSmoke.vue";
import windowsScreen from "@/views/weibao/components/windowsScreen.vue";

// 策略模式
let modalType = {
  'zoomImg': function (data) {
    this.zoomImgShow = true
    this.modalData = data;
  },
  'fireController': function (data) {
    this.fireControllerShow = true
    this.modalData = data;
  },
  'waterPump': function (data) {
    this.waterPumpShow = true
    this.modalData = data;
  },
  terminalElectric: function (data){
    this.terminalElectricShow = true
    this.modalData = data;
  },
  antechamber:function (data) {
    this.antechamberShow = true
    this.modalData = data;
  },
  smoke:function (data) {
    this.smokeShow = true
    this.modalData = data;
  },
  windowsScreen:function (data) {
    this.windowsScreenShow = true
    this.modalData = data;
  },
  fireControllerShow: false,
  waterPumpShow: false,
  zoomImgShow: false,
  toolboxShow:false,
  smokeShow:false,
  terminalElectricShow:false,
  antechamberShow:false,
  windowsScreenShow:false,
  modalData: {},
}

export default {
  data() {
    return {
      zoomInImg:require('@/assets/img/zoomIn.png'),
      isFullscreen: false,
      question: null,//所有数据
      topic: [],//当前题目
      branch:[],//当前题目的第几小题
      // 进度条
      per_:20,
      percentage: 20,
      customColor: "#409eff",
      customColors: [{ color: "#f56c6c", percentage: 20 }],
      cities: [],
      ckeckList: [],
      radio: null,
      operateAns:[],//框的选中
      activeKey: 0,//当前题目的索引
      nextShow:false,//点击下一步出现的小提示
      branchKey: 0,//点击下一步, 当前题目的第几小题索引
      imgKey: 0,//场景的第几张图
      buttondisable:false,//提交按钮, 是否点击
      tipsShow:false,//提示的显示
      submitAns:[],//每小题 提交的答案
      subDisabled:false,//提交按钮, 是否点击
      // 答案集合
      modalType,
      ansObj: {},
      init_question:{},
      secondNum:"00:00:00",//15-3的秒数
      type:'viewr',
      count:0,
      timer:null,
      timeLable:'00:00',
      apiId:'10012',
      totalScore:0,
    };
  },
  components: {
    smoke,
    zoomImg,
    fireController,
    tools,
    waterPump,
    terminalElectric,
    antechamber,
    windowsScreen
  },
  created() {
    let { question } = require(`@/api/100120`);
    this.init_question=this.deepClone(question);
    this.question = this.deepClone(question);
    this.topic = this.question["topic"];//所有题目
    this.branch=this.topic[this.activeKey]['branch'][this.branchKey]//当前第几题的第几小题
    this.nextQuestion();

    if(this.topic[this.activeKey]['ques_tips']&&this.topic[this.activeKey]['ques_tips'].length>1){
      this.nextShow=true;
    }
    this.per_=(100/this.topic.length);
    this.percentage=parseInt(this.per_*(this.activeKey+1));//进程
    this.modalClose();
    screenfull.toggle();
    this.type = this.$route.query.type
    this.count = this.$route.query.count
    if(this.type !== 'viewr'){
      this.timeCountSetInterVal()
    }
  },
  mounted() {
    // 监听全屏变化
    screenfull.onchange(() => {
      this.isFullscreen = screenfull.isFullscreen;
    })
  },
  beforeRouteLeave(to, from, next) {
    clearInterval(this.timer);
    this.timer=null;
    next(true);
  },
  methods: {
    fullscreenClick(){// 全屏
      screenfull.toggle();
    },
    timeCountSetInterVal(){    // 考试模式计时器
      if(this.timer) return
      this.timer = setInterval(() => {
        this.count = this.count - 1
        this.timeLable=fomatTime(this.count)
        if(this.count <= 0){
          clearInterval(this.timer)
          this.timer = null

          let ids = sessionStorage.getItem('ids') ? JSON.parse(sessionStorage.getItem('ids')) : []
          ids.push(this.apiId)
          sessionStorage.setItem('ids',JSON.stringify(ids))
          window.sessionStorage.setItem("topic", JSON.stringify(this.topic));
          window.sessionStorage.setItem("ansObj", JSON.stringify(this.ansObj));
          this.$router.replace("/result");
          this.reset();
        }
      },1000)
    },
    deepClone (source){
      var sourceCopy = source instanceof Array ? [] : {}
      for (var item in source) {
        sourceCopy[item] = typeof source[item] === 'object' ? this.deepClone(source[item]) : source[item]
      }
      return sourceCopy
    },
    play(val) {
      //题目
      if (!val)  return;
      let music = new Audio();
      // 这里获取播放路径
      music = require(`@/assets/audio/${val}`);
      this.$nextTick((res) => {
        this.$refs.audio.src = music;
        let { audio } = this.$refs;
        audio.load();
        audio.play();
      });
    },
    play2(val) {
      //一些按钮声音
      if (!val)  return;
      this.$refs.audio2.pause();
      let music = new Audio();
      // 这里获取播放路径
      music = require(`@/assets/audio/${val}`);
      this.$nextTick((res) => {
        this.$refs.audio2.src = music;
        let audio = this.$refs.audio2;
        audio.load();
        audio.play();
      });
    },
    play3(val,loop=false,play='play') {
      //可循环
      if(play==='stop'&&this.$refs.audio3){
        this.$refs.audio3.pause()
      }
      if (!val)  return;
      let music = new Audio();
      // 这里获取播放路径
      music = require(`@/assets/audio/${val}`);
      this.$nextTick((res) => {
        this.$refs.audio3.src = music;
        let audio = this.$refs.audio3;
        audio.loop=loop;
        audio.load();
        audio.play();
      });
    },
    goBack() {
      this.$router.go(-1);
      this.play2('button.mp3');
    },
    freBoxSubmit(ckeckList){
      let ans = this.question.topic[this.activeKey]["choose"]["ans"];
      this.ansObj[this.activeKey] = arraysAreEqual(ans, ckeckList);
    },
    async onSubmit() {
      if(this.subDisabled){
        return false;
      }

      let ans = this.topic[this.activeKey]["ans"];//
      this.ansObj[this.activeKey] = arraysAreEqual(ans, this.submitAns[this.activeKey])
      // console.log('提交',this.submitAns[this.activeKey],'答案',ans,'结果',this.ansObj[this.activeKey])

      if(this.ansObj[this.activeKey]){
        this.totalScore+=parseFloat(this.topic[this.activeKey]['score'])
      }
      let sorceObj = sessionStorage.getItem("sorceObj")? JSON.parse(sessionStorage.getItem("sorceObj")):[];
      sorceObj[this.apiId] = this.totalScore
      sessionStorage.setItem("sorceObj",JSON.stringify(sorceObj));

      if (this.activeKey < this.topic.length - 1) {
        this.activeKey = this.activeKey + 1;//下一题
        if(this.topic[this.activeKey]['branch']&&this.topic[this.activeKey]['branch'][this.branchKey+1]){
          this.nextShow=true;
        }
        else{
          this.nextShow=false;
        }
      }
      else {
        this.play2('select.mp3');

        sessionStorage.setItem("topic", JSON.stringify(this.topic));
        sessionStorage.setItem("ansObj", JSON.stringify(this.ansObj));
        let ids = sessionStorage.getItem('ids') ? JSON.parse(sessionStorage.getItem('ids')) : []
        ids.push(this.apiId)
        sessionStorage.setItem('ids',JSON.stringify(ids))
        // console.log('做完啦',JSON.stringify(this.ansObj))
        setTimeout(() => {
          this.$router.replace("/result");
        }, 500);
        return true;
      }
      this.reset();
    },
    reset(type=0) {
      //重做
      //答案清空
      this.ckeckList = [];
      this.radio = null;
      this.submitAns=[];
      //索引情况
      this.branchKey=0;
      //按钮清除
      this.modalClose();
      //场景图索引
      this.imgKey=0;
      //
      this.play2('select.mp3');
      this.play3('','','stop');
      //重置需要清除改变的参数
      if(type===1){
        this.topic = this.deepClone(this.init_question["topic"]);
        this.branch=this.topic[this.activeKey]['branch'][this.branchKey]//当前第几题的第几小题
      }
      this.nextQuestion();
    },

    // ===================
    submitAnswer(value){
      if(typeof value==='object'){
        value.sort();
      }
      // console.log(this.branchKey,value)
      //每小题选择的答案
      if(!this.submitAns[this.activeKey]){
        this.submitAns[this.activeKey]=[];
      }

      if((this.branch.ansStep||this.branch.ansStep===0)){
        this.submitAns[this.activeKey][this.branch.ansBranch][this.branch.ansStep]=value;
      }
      else if(this.branch.ansBranch||this.branch.ansBranch===0){
        this.submitAns[this.activeKey][this.branch.ansBranch]=value;
      }
      else{
        this.submitAns[this.activeKey][this.branchKey]=value;
      }
      this.play2('select.mp3');
    },
    handleMusicPlay(){
      // this.buttondisable=true;
      if(this.nextShow){
        this.subDisabled = true;
      }
    },
    handleMusicEnded() {
      this.buttondisable=false;
      if(this.topic[this.activeKey]['branch'].length===1){
        this.subDisabled=false;
      }
    },
    next(event){
      //点击下一步, 下一题
      if(this.buttondisable){
        return false;
      }
      this.branchKey++;
      if(this.topic[this.activeKey]['branch'][this.branchKey]){
        this.branch=this.topic[this.activeKey]['branch'][this.branchKey];
        if(this.topic[this.activeKey]['branch'][this.branchKey+1]){
          this.nextShow=true;
        }
        else{
          this.nextShow=false
          this.subDisabled=false;
        }
        if(!this.submitAns[this.activeKey]){
          this.submitAns[this.activeKey]=[];
        }
        if(!this.submitAns[this.activeKey][this.branchKey]){
          this.submitAns[this.activeKey][this.branchKey]=0;
        }

        //有场景切换, 背景图不变
        let bg_=this.topic[this.activeKey]['scene_change'][this.imgKey]['bg']
        this.branch['bg_img']=bg_;
      }
      else{
        this.nextShow=false
        this.subDisabled=false;
      }
      //如果有选择框,删除选择框
      var box_frame_bg=document.querySelectorAll('.box_frame_bg');
      for(let i=0;i<box_frame_bg.length;i++){
        box_frame_bg[i].classList.remove('box_frame_bg')
      }

      this.modalClose();
      this.play2('button.mp3')

    },
    nextQuestion(){
      if(this.$refs.tools){
        this.$refs.tools.removeBg();
      }
      //
      //提交后的 ,下一题的处理,如题目播放, 是否随机题目
      this.percentage=parseInt(this.per_*(this.activeKey+1));//进程
      this.branchKey=0;
      this.imgKey=0;

      this.branch=this.topic[this.activeKey]['branch'][this.branchKey];
      this.play(this.topic[this.activeKey]["audio"])
      if(this.branch['audio']){
        this.play3(this.branch['audio'],true)
      }

      if(this.topic[this.activeKey]['branch'][this.branchKey+1]) {
        this.nextShow = true;
      }
      if(this.topic[this.activeKey]['defautans']){
        this.submitAns[this.activeKey]=this.topic[this.activeKey]['defautans'];
      }
      //如果有场景切换
      if(this.branch['sceneChangeShow']){
        this.sceneChange(this.imgKey);
      }
      if(this.activeKey===0&&this.branch["ansType"]&&this.branch["ansType"]===2){
        //第一题 随机答案
        var nums=[1,2,''];
        var rand=Math.floor(Math.random() * nums.length);
        rand=nums[rand];
        this.branch.choose=this.branch['choose'+rand];
      }
      else if(this.activeKey===2&&this.branch["ansType"]&&this.branch["ansType"]===2){
        var nums=[1,2,''];
        var rand=Math.floor(Math.random() * nums.length);
        rand=nums[rand];
        this.branch.choose=this.branch['choose'+rand];
      }
      if(this.topic[this.activeKey].toolboxShow){
        this.modalType.toolboxShow=true;
      }
      else{
        this.modalType.toolboxShow=false;
      }
    },
    modalClose(){
      //关闭所有弹窗和提示
      this.tipsShow=false;//提示
      this.modalType.fireControllerShow=false;//消控室
      this.modalType.waterPumpShow=false;//水泵房
      this.modalType.zoomImgShow=false;//图片
      this.modalType.toolboxShow=false;//工具箱
      this.modalType.terminalElectricShow=false;//末端配电装置
      this.modalType.antechamberShow=false;//风机房 -前室
      this.modalType.smokeShow=false;//排烟口-房间
      this.modalType.windowsScreenShow=false;
    },
    showTips(){//显示提示
      if(this.tipsShow===false){
        this.tipsShow=true;
      }
      else{
        this.tipsShow=false;
      }
      this.play2('button.mp3');
    },
    sceneChange(index_){
      //场景小图切换
      this.imgKey=index_;
      let scene_change=this.topic[this.activeKey]['scene_change'][index_];
      this.branch ['bg_img']=scene_change['bg']

      if(document.querySelector('.nav.actived')){
        document.querySelector('.nav.actived').classList.remove('actived');
      }
      if(document.querySelectorAll('.nav')[index_]){
        document.querySelectorAll('.nav')[index_].classList.add('actived')
      }
      var box_frame_bg=document.querySelectorAll('.box_frame_bg');
      if(box_frame_bg){
        for(let i=0;i<box_frame_bg.length;i++){
          box_frame_bg[i].classList.remove('box_frame_bg')
        }
      }

      //如果有工具箱出现
      if(scene_change.toolboxShow){
        this.modalType.toolboxShow=true;
      }
      else{
        this.modalType.toolboxShow=false;
      }
      //如果有声音
      if(scene_change.audio){
        this.play3(scene_change.audio,true);
      }
      else{
        this.play3('',false,'stop');
      }
    },
    operateAnswer(val,e){
      //操作题的答案,
      var this_ = e.currentTarget;
      if (this_) {
        if (this_.classList.contains("box_frame_bg")) {
          this_.classList.remove("box_frame_bg");
        } else {
          this_.classList.add("box_frame_bg");
        }
      }
      if (!this.operateAns[this.activeKey]) {
        this.operateAns[this.activeKey] = [];
      }

      if(this.operateAns[this.activeKey].indexOf(val)===-1){
        this.operateAns[this.activeKey].push(val) ;
      }
      else{
        this.operateAns[this.activeKey].splice(this.operateAns[this.activeKey].indexOf(val),1);
      }
      this.operateAns[this.activeKey].sort();
      this.submitAns[this.activeKey][this.branchKey]=this.operateAns[this.activeKey];
    },
    showZoomImg(zoom,type='img'){
      //点击放大镜
      zoom.branchKey=this.branchKey;
      this.modalType[zoom['modalType']](zoom)
      this.play2('magnifier-da.mp3');
    },
    subAnswer(value){
      //子组件, 提交答案
      let branchKey=value[0];//
      let val=value[1];
      let step=-1;
      if(value[2]){
        step=value[2];
      }
      if(!this.submitAns[this.activeKey]){
        this.submitAns[this.activeKey]=[];
      }
      if(typeof val==='object'){
        let this_=this;
        Object.keys(val).forEach(function (k,v) {
          this_.submitAns[this_.activeKey][branchKey][k]=val[k];
        })
      }
      else{
        this.submitAns[this.activeKey][branchKey]=val;
      }
    },
    subToolAnswer(value,branch=-1,next=0){
      //工具箱提交答案,不同的题处理不一样
      let this_=this;
      // console.log('subToolAnswer',value,this.activeKey,this_.branchKey,this_.branch['tool'+value])

      if (this_.branch['tool'+value]&&(this_.branch['tool'+value]['submit'])) {
        let sub=this_.branch['tool'+value]['submit'];
        if ((sub['equal'] === 1 && this.branchKey === sub['branch']) || (sub['equal'] === 3)) {
          this.subAnswer([sub['branch'], sub['ans']])
        }
        else if(sub['equal'] === 4&&sub['branch'+sub['branch']]){
          var sub_=sub['branch'+sub['branch']]
          this.subAnswer([this.branchKey, sub_.ans])
          var update=this_.branch['tool'+value]['update'+sub['branch']]
          this_.subUpdate(update)
          if(sub_.next){
            this_.branchKey=sub_.next;
          }
          return true;
        }
        if(sub.next){
          this.branchKey=sub.next;
        }
      }
      if(this_.branch['tool'+value]['update']){
        var update=this_.branch['tool'+value]['update'];
        this_.subUpdate(update)
      }
    },
    customOther( sy,sub,update_=[]){
      // console.log('customOther', sy, sub,update_, this.activeKey,this.branchKey)
      //有答案提交
      if (sub['ans']) {
        if ((sub['equal'] === 1 && this.branchKey === sub['branch']) || (sub['equal'] === 3)) {
          this.subAnswer([sub['branch'], sub['ans']])
        }
        else if(sub['equal']===4&&sub['branch'+sub.branch]){
          sub=sub['branch'+sub.branch]
          this.subAnswer([sub['branch'], sub.ans])
        }
      }
      //有参数变化
      if(update_.length>0||Object.keys(update_).length){
        if (!sub['equal']||(sub['equal'] === 1 && this.branchKey === sub['branch']) || (sub['equal'] === 3)) {
          this.subUpdate(update_);
        }
        else if(sub['equal']===4){
          this.subUpdate(update_)
        }
      }
      //小题索引改变
      if (!sub['equal']||(sub['equal'] === 1 && this.branchKey === sub['branch']) || (sub['equal'] === 3)||sub['equal']===4) {
        if (sub['next'] && sub['next'] > 0) {
          this.branchKey = sub['next'];
          this.modalType.modalData.branchKey=this.branchKey;
        }
      }

    },
    subUpdate(data){
      //子组件操作修改其他放大镜下的参数
      // console.log('subUpdate',data)
      let this_=this;
      if(data.zoom){
        Object.keys(data.zoom).forEach((k,v)=>{
          let up_=data.zoom[k];
          if(up_.type&&up_.type==='self'){
            up_.up[up_.key]=this.topic[this.activeKey]['zoomIn'][up_.index]['zoom'][up_.val];
          }
          this_.topic[this.activeKey]['zoomIn'][up_.index]['zoom']=this_.arrcover(up_.up,this.topic[this.activeKey]['zoomIn'][up_.index]['zoom'])
        })
      }
      if(data.scene_change){
        Object.keys(data.scene_change).forEach((k,v)=>{
          let up_=data.scene_change[k];
          this_.topic[this.activeKey]['scene_change'][up_.index]=this_.arrcover(up_.up,this_.topic[this.activeKey]['scene_change'][up_.index])
        })
      }
      if(data.audio){
        if(typeof data.audio==='object'){
          Object.keys(data.audio).forEach(function (k,v) {
            if(!data.audio[k]['index']||data.audio[k]['index']===3){
              this_.play3(data.audio[k]['url'],data.audio[k]['loop'],data.audio[k]['play'])
            }
            else if(data.audio[k]['index']&&(data.audio[k]['index']===1)){
              this_.play(data.audio[k]['url'])
            }
            else if(data.audio[k]['index']&&data.audio[k]['index']===2){
              this_.play2(data.audio[k]['url'])
            }
          })
        }
        else{
          this.play3(data.audio.url,data.audio.type)
        }
      }
      if(data.branch){
        Object.keys(data.branch).forEach((k,v)=>{
          let up_=data.branch[k];
          if(up_.type&&up_.type==='self'){
            up_.up[up_.key]=this_.topic[this.activeKey]['branch'][up_.index][up_.val];
          }
          this_.topic[this.activeKey]['branch'][up_.index]=this_.arrcover(up_.up,this_.topic[this.activeKey]['branch'][up_.index])

          if(up_.key===this.branchKey){
            this.branch=this_.topic[this.activeKey]['branch'][up_.index];
          }
        })
      }
      if(data.toolOther){
        Object.keys(data.toolOther).forEach((k,v)=>{
          let up_=data.toolOther[k];
          this_.branch['toolOther'][up_.index]=this_.arrcover(up_.up,this_.branch['toolOther'][up_.index])
        })
      }
      if(data.delay){
        setTimeout(function () {
          this_.$nextTick(() => {
            this_.subUpdate(data.delay)
          })
        },data.delay.time);
      }
    },
    arrcover(arr,new_){
      Object.keys(arr).forEach((k)=>{
        if(typeof arr[k] === 'object'){
          if(!new_[k]){
            new_[k]={};
          }
          this.arrcover(arr[k],new_[k])
        }
        else{
          new_[k]=arr[k];
        }
      })
      return new_;
    },
  },
};
</script>
<style lang="less" scoped>
.question {
  position: relative;
  height: 100%;
  width: 100%;
  // ======
}

.main-container {
  min-height: 100%;
  transition: margin-left 0.28s;
  margin-left: 0;
  position: relative;
  background: #f8f8f8;
}
.app-main {
  position: relative;
}
.container_full{ // 全屏下
  width: 100vw !important;
  height: 100vh !important;
  margin: 0 auto !important;
  position: relative !important;
  //.shutter-box{
  //  top: 30.738% !important;
  //  left: 30.36% !important;
  //  height: 45.9334% !important;
  //  width: 46.18% !important;
  //  position: absolute;
  //  z-index: 2;
  //}
}
.container {
  .fullscreen {
    position: absolute;
    bottom: -3.125rem;
  }
  // 非全屏下
  width: 120rem;
  height: 67.5rem;
  margin: 0 auto;
  position: relative;
  padding-bottom: 20px;
  .main-background {
    position: absolute;
    inset: 0 0 0 0;
    z-index: 0;
    overflow: hidden;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    // width: 100%;
    // height: 100%;
  }
  .title {
    height: 2.5rem;
    background-color: #409eff;
    line-height: 2.5rem;
    color: #fff;
    font-weight: 500;
    padding: 0 1.25rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: absolute;
    z-index: 8;
    left: 0;
    top: 0;
    width: 100%;
    font-size: 2rem;
    .ct {
      margin-left: auto;
    }
  }
  .main {
    position: relative;
    height: 100%;
    background-size: cover;
    background-position: bottom;
    background-repeat: no-repeat;
    box-sizing: border-box;
    background-attachment: fixed;
    .sub-title {
      height: 2.5rem;
      background-color: #fff;
      padding: 0 1.25rem;
      position: absolute;
      z-index: 8;
      left: 0;
      top: 2.5rem;
      width: 100%;
      font-size: 1.5rem;
      .progress {
        font-size: 0.875rem;
        span {
          font-size: 0.8125rem;
          margin: 0 0.3125rem;
        }
      }
    }
    // 选这题
    .choose {
      position: absolute;
      left: 1.25rem;
      top: 6.25rem;
      min-width: 12.5rem;
      max-width: 21.875rem;
      background-color: #409eff;
      z-index: 99999;
      border-radius: 0.625rem;
      box-shadow: 1px 1px 5px #ccc;
      box-sizing: border-box;
      .choose-title {
        color: #fff;
        padding: 0.625rem;
        font-size: 1.5rem;
      }
      .choose-option {
        background-color: #fff;
        padding: 1.25rem 0.625rem;
        border-radius: 0 0 0.625rem 0.625rem;
        .option {
          margin-bottom: 1.25rem;
          font-size: 1.5rem;
        }
      }
    }

    .operate {
      position: absolute;
      left: 1.25rem;
      top: 6.25rem;
      min-width: 12.5rem;
      max-width: 21.875rem;
      background-color: #409eff;
      z-index: 99999;
      border-radius: 0.625rem;
      box-shadow: 1px 1px 5px #ccc;
      box-sizing: border-box;
      .operate-title {
        color: #fff;
        padding: 0.625rem;
        font-size: 1.5rem;
      }
      .choose-option {
        background-color: #fff;
        padding: 1.25rem 0.625rem;
        border-radius: 0 0 0.625rem 0.625rem;
        .option {
          margin-bottom: 1.25rem;
          font-size: 1.5rem;
        }
      }
    }

  }

  //   按钮
  .toolbar {
    position: absolute;
    left: 0;
    width: 100%;
    bottom: 2.5rem;
    z-index: 9999;
  }
  .toolbar-content {
    margin: 0 12.5rem;
    .right-btn {
      margin-left: auto;
    }
    button {
      padding: 0.625rem 1.875rem;
      color: #fff;
      font-size: 16px;
      margin: 0 1.875rem;
      border-radius: 0.3125rem;
      cursor: pointer;
    }
  }
  .toolbar-content .btns,
  .toolbar-content .right-btn {
    display: flex;
    align-items: center;
  }
  .toolbar-content .btns {
    position: absolute;
    z-index: 9999;
    left: 6.25rem;
    right: 6.25rem;
    bottom: 0;
  }

  .navs {
    position: absolute;
    right: 2.5rem;
    bottom: 10rem;
    z-index: 334;
    .navs-header {
      background-color: #409eff;
      border-radius: 0.625rem 0.625rem 0 0;
      color: #fff;
      font-size: 1rem;
      padding: 0.625rem 0.9375rem;
    }
    .navs-list {
      display: flex;
      -ms-flex-direction: column;
      flex-direction: column;
      background-color: #fff;
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
      padding: 0.9375rem 0.9375rem;
      border-radius: 0 0 0.625rem 0.625rem;
      //height: 9.375rem;
      //overflow-y: auto;
      .nav {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        width: 13.125rem;
        margin-bottom: 0.9375rem;
        cursor: pointer;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        border: 1px solid #fff;
        img {
          width: 5rem;
          height: 3.125rem;
          -o-object-fit: cover;
          object-fit: cover;
          border-radius: 0.3125rem 0 0 0.3125rem;
        }
        span {
          -webkit-box-flex: 1;
          -ms-flex: 1;
          flex: 1;
          text-align: center;
        }
      }
      .actived {
        border: 1px solid #409eff;
        color: #409eff;
        border-radius: 0.3125rem;
        font-weight: 500;
      }
      .nav:last-child {
        margin-bottom: 0;
      }
    }
  }
  //可以选择的框
  .box_frame{
    border:2px solid blue;
    position: absolute;
  }
  .box_frame_bg{
    background: #6b82f5;
    opacity: 0.7;
  }
  //提示显示
  .tips_show{
    z-index: 2009;
    border-radius: 0.3125rem;
    border: 0.3125rem solid #ccc;
    position: absolute;
    background: #fdfdfd;
    width: 50%;
    top: 20%;
    left: 25%;
    padding: 1rem;
    font-size:1.15rem ;
    div{
      line-height: 35px ;
      //text-indent: 1rem;
    }
  }
  /deep/ .toolboxs .toolbox {
    top: 11%;
    z-index: 2003;
  }
}

.container .main .sub-title .progress,
.container .main .sub-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.zoom {
  position: absolute;
  cursor: pointer;
  img {
    width: 2.5rem;
    height: 2.5rem;
  }
}
/deep/.zoomOut{
  position: absolute;
  width: 2.5rem;
  height: 2.5rem;
}
/deep/.el-dialog__wrapper{
  z-index: 2000 !important;
}
.displayshow{
  display: block !important;
}
.shutter.close{
  height: 100%;
}
.shutter{
  width: 100%;
  position: relative;
  background-color: #185ca1;
  background: url(../assets/img/wei16/shutter-cover.png) repeat-y;
  background-size: 100% auto;
  height: 10.5%;
  -webkit-transition: height 8.2s linear;
  transition: height 8.2s linear;
  .side{
    position: absolute;
    left: 0;
    right: 0;
    bottom: -.1875rem;
    height: .5625rem;
    background: url(../assets/img/wei16/shutter-cover-line.png) repeat-y;
    background-size: 100% auto;
  }
}

.displaynone{
  display: none !important;
}
.smoke-tool-block{
  opacity: 0; /* 开始时不显示 */
  animation: showAndHide 2s infinite;
  animation-iteration-count: 1;
}
@keyframes showAndHide {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1; /* 50%的时候完全显示 */
  }
  90% {
    opacity: 1; /* 90%的时候完全显示 */
  }
  100% {
    opacity: 0; /* 结束时又不显示 */
  }
}

.opacity-show{
  opacity: 1 !important;
}
.fengkous{
  position: absolute;
  //border: 1px solid;
  top: 53.5%;
  left: 44.7%;
  width: 18.5%;
  height: auto;overflow: hidden;

  .fengkou{
    display: flex;
    justify-content: space-around;
    width: 100%;
    margin-bottom: 22%;
    .kou{
      flex: 1;
      width:4.5rem ;
      height: 4.5rem;
      border: 0.15rem solid green;
      margin-right: 17%;
    }
    .kou:nth-child(3n){
      margin-right:0;
    }
  }
  .fengkou:last-child{
    margin-bottom: 0;
  }
}
</style>
